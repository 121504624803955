<template>
  <div class="course-detail">
    <div v-cloak class="infomat">
      <img :src="imgUrl" alt="" class="userPic" />
      <div class="userMsg">
        <p class="CourseMsg">{{ CourseData.courseName }}</p>
        <span class="charact">课程特点：{{ CourseData.introduction }}</span>
        <p class="charact">授课讲师：{{ CourseData.internalTeacherName }}</p>
      </div>
    </div>
    <div class="main">
      <el-tabs v-model="activeTab">
        <el-tab-pane label="课程详情" name="1">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div class="html" v-html="CourseData.summary"></div>
        </el-tab-pane>
        <el-tab-pane label="课程目录" name="2">
          <div class="section">
            <p v-for="(item, index) in sectionData" :key="index" class="item-span">
              第<span v-show="index < 9">0</span>{{ index + 1 }}节 {{ item.sectionName }}
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="课件资料" name="3">
          <div class="List">
            <div v-for="(items, index) in respList" :key="index" class="infox">
              <img src="@/assets/teacher/fileicon.png" alt="" class="file-image" />
              <p class="content" @click="exportdoc(items.url)">
                {{ items.docName }}
              </p>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import to from 'await-to'
import {
  TrainerCoursedetail,
  trainerCourseSections,
  trainerCourseattachListRespList,
} from '@/api/teacher'
export default {
  data() {
    return {
      courseId: parseInt(this.$route.query.courseId),
      CourseData: {},
      imgUrl: '',
      activeTab: '1',
      sectionData: [],
      respList: [],
    }
  },
  mounted() {
    this.handlegetCourseInfo()
    this.handlegetCourseSections()
    this.handleGetCourserespList()
  },
  methods: {
    async handlegetCourseInfo() {
      const [res, err] = await to(TrainerCoursedetail({ courseId: this.courseId }))
      if (err) return this.$message.warning(err.msg)
      res.data.summary = res.data.summary.replace(
        /<img/g,
        "<img style='max-width: 100% !important;'",
      )
      this.CourseData = res.data
      this.imgUrl = res.data.pic
    },
    async handlegetCourseSections() {
      const [res, err] = await to(trainerCourseSections({ courseId: this.courseId }))
      if (err) return this.$message.warning(err.msg)
      this.sectionData = res.data
    },
    async handleGetCourserespList() {
      const [res, err] = await to(trainerCourseattachListRespList({ courseId: this.courseId }))
      if (err) return this.$message.warning(err.msg)
      this.respList = res.data
    },
    exportdoc(url) {
      window.location = url
    },
  },
}
</script>
<style scoped>
.infomat {
  width: 100%;
  background-color: #fff;
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
}
.userPic {
  display: block;
  width: 510px;
  height: 280px;
  margin: 20px 20px 20px 20px;
}
.userMsg {
  margin: 20px 15px;
  position: relative;
}
.CourseMsg {
  margin-bottom: 15px;
  font-size: 24px;
  color: #333333;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  font-weight: 400;
}
.charact {
  margin-top: 15px;
  font-size: 14px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  text-align: left;
  color: #666666;
  line-height: 16px;
}
.main {
  width: 100%;
  margin-top: 20px;
  background: #fff;
}
.main >>> .el-tabs__item {
  font-size: 18px;
  font-weight: normal;
}
.main >>> .el-tabs__nav {
  margin: 20px 0 0 20px;
}
.main >>> .el-tabs__item.is-active {
  color: #ff7b33;
}
.main >>> .el-tabs__active-bar {
  background-color: #ff7b33;
}
.main >>> .el-tabs__item:hover {
  color: #ff7b33;
}
.html {
  margin: 10px 20px;
}
.section {
  margin: 0px 20px;
}
/* .section span {
  display: block;
  font-size: 16px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  text-align: left;
  color: #666666;
  margin: 10px 0;
} */
.item-span {
  font-size: 16px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  text-align: left;
  color: #666666;
  margin: 10px 0;
}
.List {
  margin: 10px 20px;
}
.infox {
  width: 100%;
  margin: 10px 0 15px 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: wrap;
  cursor: pointer;
}
.file-image {
  width: 30px;
  height: 30px;
}
.content {
  font-size: 14px;
  font-family: Alibaba PuHuiTi, Alibaba PuHuiTi-Medium;
  text-align: left;
  color: #3e3e3e;
  line-height: 20px;
  margin: 5px 10px;
  cursor: pointer;
}
</style>
